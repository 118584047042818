import * as Sentry from "@sentry/browser";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import holographicVertexShader from "./shaders/holographic/vertex.glsl";
import holographicFragmentShader from "./shaders/holographic/fragment.glsl";

import rainVertexShader from "./shaders/matrixRain/vertex.glsl";
import rainFragmentShader from "./shaders/matrixRain/fragment.glsl";

import bluepillVertexShader from "./shaders/sphereVFX/vertex.glsl";
import bluepillFragmentShader from "./shaders/sphereVFX/fragment.glsl";

import gsap from "gsap";
import TWEEN from "@tweenjs/tween.js";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";
import { BokehPass } from "three/addons/postprocessing/BokehPass.js";

import handss from "/hands.png";
import morpheuss from "/morpheus.png";


// SENTRY
Sentry.init({
    dsn: "https://d877c1f0777021b9b16dd02a1312dc1b@o4507525605031936.ingest.us.sentry.io/4507525627379712",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            blockAllMedia: false,
            maskAllText: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



////////////////////////////////////////////////////////////////////////
//// HTML ELEMENTS
const redbutton = document.getElementById("redChoice");
const bluebutton = document.getElementById("blueChoice");

////////////////////////////////////////////////////////////////////////
//// LOADING MANAGER
const loadingBarElement = document.querySelector(".loader");
const mainContainer = document.querySelector(".main-container");

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    loadingBarElement.style.visibility = "hidden";
    introAnimation();
    mainContainer.style.visibility = "visible";
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    // Calculate the progress and update the loadingBarElement
    const progressRatio = itemsLoaded / itemsTotal;
    console.log(progressRatio);
  }
);

// Canvas
const canvas = document.querySelector("canvas.webgl");

////////////////////////////////////////////////////////////////////////
//// VARIABLES
let progress = { value: 0 };
let explodeDirection;
let explodeDirection2;
let isClicked = false;
let cursor = { x: 0, y: 0 };

// Scene
const scene = new THREE.Scene();

// Loaders
const gltfLoader = new GLTFLoader(loadingManager);

////////////////////////////////////////////////////////////////////////
//// SIZES

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

////////////////////////////////////////////////////////////////////////
//// Camera

const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(
  30,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(0, 0.3, 12);
camera.rotation.set(Math.PI / 2, 0, 0);
scene.add(camera);

cameraGroup.add(camera);

////////////////////////////////////////////////////////////////////////
//// Renderer

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true,
});
renderer.setClearColor(0xffffff, 0);
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/////////////////////////////////////////////////////////////////////////
///// POST PROCESSING EFFECTS

const renderPass = new RenderPass(scene, camera);
const renderTarget = new THREE.WebGLRenderTarget(sizes.width, sizes.height, {
  minFilter: THREE.LinearFilter,
  magFilter: THREE.LinearFilter,
  format: THREE.RGBAFormat,
});

const composer = new EffectComposer(renderer, renderTarget);
composer.setPixelRatio(Math.min(window.devicePixelRatio, 1));

composer.addPass(renderPass);

/// BLOOM
const bloomPass = new UnrealBloomPass(
  new THREE.Vector2(sizes.width, sizes.height),
  0.1,
  0.2,
  0.2
);

const bokehPass = new BokehPass(scene, camera, {
  focus: 2.5,
  aperture: 0.25 * 0.00001,
  maxblur: 0.008,

  width: sizes.width,
  height: sizes.height,
});

// bloomPass.threshold = 0.5;
// bloomPass.strength = 1.5;
// bloomPass.radius = 0.5;

composer.addPass(bloomPass);
composer.addPass(bokehPass);
/////////////////////////////// Objects

let blueSphere = new THREE.SphereGeometry(1, 32, 32);

let redSphere = new THREE.SphereGeometry(1, 32, 32);

// Attributes for the explosion
const directions = [];
const directions2 = [];
for (let i = 0; i < redSphere.attributes.position.count; i++) {
  const direction = new THREE.Vector3(
    (Math.random() - 0.5) * 2,
    (Math.random() - 0.5) * 2,
    (Math.random() - 0.5) * 2
  );
  directions.push(direction.x, direction.y, direction.z);
}

for (let i = 0; i < blueSphere.attributes.position.count; i++) {
  const direction = new THREE.Vector3(
    (Math.random() - 0.5) * 2,
    (Math.random() - 0.5) * 2,
    (Math.random() - 0.5) * 2
  );
  directions2.push(direction.x, direction.y, direction.z);
}

explodeDirection = new THREE.BufferAttribute(new Float32Array(directions), 3);
explodeDirection2 = explodeDirection.clone();

const material = new THREE.ShaderMaterial({
  vertexShader: holographicVertexShader,
  fragmentShader: holographicFragmentShader,
  uniforms: {
    uTime: new THREE.Uniform(0),
    uColor: new THREE.Uniform(new THREE.Color("#ff0f00")),
    progress: { value: 0 },
    iClick: { value: false },
    fade: { value: 0 },
  },
  transparent: true,
  side: THREE.DoubleSide,
  depthWrite: false,
  blending: THREE.AdditiveBlending,
});

const bluematerial = new THREE.ShaderMaterial({
  fragmentShader: bluepillFragmentShader,
  vertexShader: bluepillVertexShader,
  uniforms: {
    uTime: new THREE.Uniform(0),
    uColor: new THREE.Uniform(new THREE.Color("#0f00ff")),
    progress: { value: 0 },
    iClick: { value: false },
    fade: { value: 0 },
  },
  transparent: true,
  side: THREE.DoubleSide,
  depthWrite: false,
  blending: THREE.AdditiveBlending,
});

/// RED MESh
const sphere = new THREE.Mesh(redSphere, material);
sphere.scale.set(0, 0, 0);
scene.add(sphere);

/// BLUE MESh
const torusKnot = new THREE.Mesh(blueSphere, bluematerial);
torusKnot.scale.set(0, 0, 0);
scene.add(torusKnot);

/////////////////////////////////////////////////////////////////////////
// bluepill
let pills = [];

gltfLoader.load("./bluepill.glb", (gltf) => {
  pills[1] = gltf.scene;
  pills[1].scale.set(0, 0, 0);
  pills[1].position.set(0, 2, 0);
  pills[1].rotation.set(0, 0, Math.PI / 2);

  scene.add(pills[1]);
});

gltfLoader.load("./redpill.glb", (gltf) => {
  pills[0] = gltf.scene;
  pills[0].position.set(0, -2, 0);
  pills[0].scale.set(0, 0, 0);

  scene.add(pills[0]);
});

const pi = Math.PI;

const textureLoader = new THREE.TextureLoader(loadingManager);
const planeTexture = textureLoader.load(handss);
const morTexture = textureLoader.load(morpheuss);
planeTexture.flipY = false;

const planeMaterial = new THREE.MeshBasicMaterial({ map: planeTexture });
planeMaterial.transparent = true;
planeMaterial.side = THREE.DoubleSide;
planeMaterial.opacity = 0.01;

const morMaterial = new THREE.MeshBasicMaterial({ map: morTexture });
morMaterial.transparent = true;
morMaterial.side = THREE.DoubleSide;
morMaterial.opacity = 0.04;

gsap.to(morMaterial, { opacity: 0.8, duration: 0.8, ease: "power2.inOut" });

const plane = new THREE.Mesh(
  new THREE.PlaneGeometry(8, 6, 1, 1),
  planeMaterial
);
plane.rotation.set(-pi - Math.PI / 6, 0, 0);
//plane.position.set(0,-1,-1)
scene.add(plane);

const testPlane = new THREE.Mesh(
  new THREE.PlaneGeometry(9, 6, 1, 1),
  morMaterial
);
testPlane.rotation.set(Math.PI / 6, pi, 0);
testPlane.position.set(0, 1.5, -1);
testPlane.scale.set(0.8, 0.8, 0.8);
scene.add(testPlane);

// introAnimation();
/////////////////////////////////////////////////////////////////////////
//// LIGHTS

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

const pointLight = new THREE.PointLight(0xffffff, 4);
pointLight.position.set(2, 3, 4);
scene.add(pointLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
directionalLight.position.set(-4, 4, 5);

scene.add(directionalLight);

const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/////////////////////////////////////////////////////////////////////////
//// OVERLAY

const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  // wireframe: true,
  transparent: true,
  uniforms: {
    uAlpha: { value: 0 },
  },
  vertexShader: `
        varying vec2 vUv;
        void main()
        {
            vUv = uv;
            gl_Position = vec4(position, 1.0);
        }
    `,
  fragmentShader: `
        varying vec2 vUv;
        uniform float uAlpha;

        void main()
        {
            vec2 center = vec2(0.5, 0.5);
            float radius = uAlpha; 

            // Distance from center
            float dist = distance(vUv, center);

            float alpha =  smoothstep(radius,1.0 -  radius , dist);


            // gl_FragColor = vec4(0.0, 0.0, 0.0, alpha);
            gl_FragColor = vec4(1.0, 1.0, 1.0, uAlpha);
        }
    `,
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

import whiteN from "/whiteNoise.png";
import rainIMG from "/rain.png";

/////////////////////////////////////////////////////////////////////////
//// MATRIX RAIN
const rainMaterial = new THREE.ShaderMaterial({
  uniforms: {
    iTime: { value: 0.0 },
    iResolution: { value: new THREE.Vector2(sizes.width, sizes.height) },
    iMouse: { value: cursor },
  },
  vertexShader: rainVertexShader,
  fragmentShader: rainFragmentShader,
  side: THREE.DoubleSide,
});

rainMaterial.transparent = true;

const rainPlain = new THREE.Mesh(
  new THREE.PlaneGeometry(20, 20, 1, 1),
  rainMaterial
);

rainPlain.position.set(0, 0, -4);
rainPlain.rotation.set(0, 0, Math.PI / 2);
scene.add(rainPlain);

/////////////////////////////////////////////////////////////////////////
//// RESPONSIVE

// Function to check if viewport is mobile
function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth <= 800;
}

// Adjust cube position if mobile
if (isMobile()) {
  sphere.position.set(-0.5, -1.5, 0);
  //sphere.scale.set(0.3, 0.3, 0.3)

  torusKnot.position.set(0.5, -1.5, 0);
  //torusKnot.scale.set(0.3, 0.3, 0.3)

  plane.position.set(0, -0.6, -30);
  //plane.scale.set(10, 5, 0)
  plane.scale.set(0, 0, 0);

  console.log("Mobile");
} else {
  //sphere.position.set(-1.4, 0, 0)
  //sphere.scale.set(0.5, 0.5, 0.5)

  //torusKnot.position.set(1.4, 0, 0)
  //torusKnot.scale.set(0.5, 0.5, 0.5)

  plane.scale.set(1, 1, 1);
  plane.position.set(0, -1, -20);

  console.log("Desktop");
}

/////////////////////////////////////////////////////////////////////////
//// INTRO ANIMATION
let intAni;
function introAnimation() {
  // Wait a little
  window.setTimeout(() => {
    // Animate overlay
    //    gsap.to(plane.position, {
    //    duration: 3,

    //    delay: 1,
    //    onComplete: (() => {

    //    }),
    //});

    gsap.to(plane.position, {
      x: 0,
      y: -0.7,
      z: 0,
      duration: 1,
      ease: "power2.inOut",
    });
    gsap.to(plane.scale, {
      x: 0.4,
      y: 0.4,
      z: 0.2,
      duration: 1,
      ease: "back.out()",
    });
    gsap.to(planeMaterial, {
      opacity: 1.0,
      duration: 1,
      ease: "power2.inOut",
    });

    console.log("500");

    // Update loadingBarElement
    // loadingBarElement.classList.add("ended");
    loadingBarElement.style.visibility = "hidden";

    // loadingBarElement.style.transform = "";
  }, 1000);

  window.setTimeout(() => {
    console.log("animate");
    gsap.to(pills[0].position, {
      x: -0.65,
      y: 0.2,
      z: 0,
      duration: 0.5,
      ease: "power2.inOut",
    });
    gsap.to(pills[0].scale, {
      x: 0.3,
      y: 0.3,
      z: 0.3,
      delay: 0.2,
      duration: 0.7,
      ease: "back.out",
    });
    gsap.to(sphere.position, {
      x: -0.65,
      y: 0.2,
      z: 0,
      duration: 0.5,
      ease: "power2.inOut",
    });
    gsap.to(sphere.scale, {
      x: 0.2,
      y: 0.2,
      z: 0.2,
      delay: 0.2,
      duration: 0.7,
      ease: "back.out",
    });

    gsap.to(pills[1].position, {
      x: 0.65,
      y: 0.2,
      z: 0,
      delay: 0.2,
      duration: 0.5,
      ease: "power2.inOut",
    });
    gsap.to(pills[1].scale, {
      x: 0.3,
      y: 0.3,
      z: 0.3,
      delay: 0.4,
      duration: 0.7,
      ease: "back.out",
    });
    gsap.to(torusKnot.position, {
      x: 0.65,
      y: 0.2,
      z: 0,
      delay: 0.2,
      duration: 0.5,
      ease: "power2.inOut",
    });
    gsap.to(torusKnot.scale, {
      x: 0.2,
      y: 0.2,
      z: 0.2,
      delay: 0.4,
      duration: 0.7,
      ease: "back.out",
    });
  }, 2000);
}

// Animation variables
let isAnimating = false;
let rotationSpeed = 0.01;
let acceleration = 0.0015;

let currentPill = null;
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update material
  material.uniforms.uTime.value = elapsedTime;
  bluematerial.uniforms.uTime.value = elapsedTime * 2.0;
  rainMaterial.uniforms.iTime.value = elapsedTime;

  if (isAnimating) {
    if (currentPill === pills[0]) {
      rotationSpeed += acceleration;
      pills[0].rotation.y += rotationSpeed;

      setTimeout(() => {
        outro();
      }, 100);
    } else if (currentPill === pills[1]) {
      rotationSpeed += acceleration;
      pills[1].rotation.y += rotationSpeed;

      setTimeout(() => {
        outro();
      }, 100);
    }
  }

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);
  composer.render();

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

const textHTML = document.querySelector(".hero");

function outro() {
  window.setTimeout(() => {
    // Animate overlay
    gsap.to(overlayMaterial.uniforms.uAlpha, {
      duration: 3,
      value: 1,
      delay: 1,
    });
    textHTML.style.visibility = "hidden";
  }, 100);
}

const raycaster = new THREE.Raycaster();
// Variables to track hover state

function disableButtons() {
  redbutton.disabled = true;
  bluebutton.disabled = true;
}

/////////////////////////////////// CLICK EVENT

window.document.addEventListener("click", (event) => {
  if (event.target === redbutton) {
    console.log("red");
    //scene.remove(sphere);

    gsap.to(sphere.scale, {
      y: 0.6,
      z: 0.6,
      x: 0.6,
      duration: 0.4,
    });
    redSphere.setAttribute("direction", explodeDirection);

    setTimeout(() => {
      gsap.to(pills[0].position, {
        x: 0,
        ease: "power1.inOut",
      });
      gsap.to(pills[0].scale, {
        y: 1.1,
        z: 1.1,
        x: 1.1,
        ease: "elastic.out(1, 0.3)",
        duration: 2,
      });
    }, 220);

    disableButtons();
    isClicked = !isClicked;
    material.uniforms.iClick.value = isClicked;
    gsap.to(progress, {
      value: 1,
      duration: 0.6,
      onUpdate: function () {
        material.uniforms.progress.value = progress.value;
        material.uniforms.fade.value = progress.value;
        setTimeout(() => {
          window.location.href = "https://wwwwwwwww.jodi.org/";
        }, 300);
      },
    });

    currentPill = pills[0];
    isAnimating = true;
  } else if (event.target === bluebutton) {
    console.log("blue");

    gsap.to(torusKnot.scale, {
      y: 0.6,
      z: 0.6,
      x: 0.6,
      duration: 0.4,
    });
    blueSphere.setAttribute("direction", explodeDirection2);

    setTimeout(() => {
      gsap.to(pills[1].position, {
        x: 0,
        ease: "power1.inOut",
      });
      gsap.to(pills[1].scale, {
        y: 1.1,
        z: 1.1,
        x: 1.1,
        ease: "elastic.out(1, 0.3)",
        duration: 2,
      });
    }, 220);

    disableButtons();

    isClicked = !isClicked;
    bluematerial.uniforms.iClick.value = isClicked;
    gsap.to(progress, {
      value: 1,
      duration: 0.6,
      onUpdate: function () {
        bluematerial.uniforms.progress.value = progress.value;
        bluematerial.uniforms.fade.value = progress.value;
        setTimeout(() => {
          window.location.href = "https://lab.hakim.se/ticktock/";
        }, 300);
      },
    });

    currentPill = pills[1];
    isAnimating = true;
  }
});

window.addEventListener("mousemove", (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = -(event.clientY / sizes.height - 0.5);

  rainMaterial.uniforms.iMouse.value = new THREE.Vector2(cursor.x, cursor.y);
});
